
import robonomics from "../robonomics";
import config from "../config";

export default {
  data() {
    return {
      account: null,
      accounts: [],
      devices: []
    };
  },
  async created() {
    const devices = await robonomics.rws.getDevices(config.subscription);
    if (!devices.isEmpty) {
      this.devices = devices.toArray().map((item) => item.toString());
    }
    robonomics.accountManager.onReady(() => {
      this.accounts = robonomics.accountManager.getAccounts();
      if (this.accounts.length) {
        this.account = this.accounts[0].address;
      }
    });
  },
  computed: {
    isRws() {
      if (this.devices.includes(this.account)) {
        return true;
      }
      return false;
    }
  },
  watch: {
    async account(address) {
      await robonomics.accountManager.selectAccountByAddress(address);
    },
    isRws() {
      if (this.isRws) {
        robonomics.accountManager.useSubscription(config.subscription);
      } else {
        robonomics.accountManager.useSubscription(false);
      }
    }
  },

  methods: {
    clipboard(text, event) {
      navigator.clipboard.writeText(text).then(function () {
        event.target.closest("a").classList.add("on");

        setTimeout(function () {
          event.target.closest("a").classList.remove("on");
        }, 5000);
      });
    }
  }
};
