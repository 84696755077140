
import AccountManager from "robonomics-interface/dist/accountManagerUi";
import robonomics from "./robonomics";
import config from "./config";

export default {
  name: "App",
  async created() {
    await robonomics.run();
    await AccountManager.initPlugin(robonomics.accountManager.keyring, {
      isDevelopment: config.isDevelopmentAccounts
    });
  },
  data() {
    return {
      locale: this.$i18n.locale
    };
  },
  watch: {
    locale(newValue) {
      this.$i18n.locale = newValue;
      localStorage.setItem("locale", newValue);
    }
  }
};
