
import robonomics from "../robonomics";
import Account from "../components/Account.vue";
import Sender from "../components/Sender.vue";

export default {
  components: {
    Account,
    Sender
  },
  data() {
    return {
      isReady: false
    };
  },
  created() {
    robonomics.onReady(() => {
      this.isReady = true;
    });
  }
};
