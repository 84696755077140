
import config from "../config";
import questionsEn from "../questions.en.json";
import questionsRu from "../questions.ru.json";
import robonomics from "../robonomics";

export default {
  props: ["loader"],
  data() {
    return {
      form: {},
      isSubscription: false,
      discord: config.discord,
      error: false
    };
  },
  created() {
    setInterval(() => {
      this.isSubscription = robonomics.accountManager.subscription;
    }, 1000);
    this.initForm();
  },
  computed: {
    questions() {
      let questions = questionsEn;
      if (this.$i18n.locale === "ru") {
        questions = questionsRu;
      }
      return questions;
    }
  },
  watch: {
    form: {
      handler(value) {
        let error = false;
        for (const key in this.form) {
          if (this.form[key] === null || this.form[key].length === 0) {
            error = this.$t("quiz.error");
            break;
          }
        }
        this.error = error;
        localStorage.setItem("lesson2quiz", JSON.stringify(value));
      },
      deep: true
    }
  },
  methods: {
    initForm() {
      let store = {};
      try {
        store = JSON.parse(localStorage.getItem("lesson2quiz")) || {};
        // eslint-disable-next-line no-empty
      } catch (_) {}
      const form = {};
      for (const key in questionsEn) {
        if (store[key]) {
          form[key] = store[key];
        } else {
          form[key] = questionsEn[key].multiple ? [] : null;
        }
      }
      this.form = form;
    },
    submit() {
      if (!this.error) {
        this.$emit("submit", this.form);
      }
    }
  }
};
